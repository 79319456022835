import React from "react";
import Select from "../../../Select";
import { FilterMainComponentProps } from "../Filter";

const GenericSelect = ({
  values,
  options,
  onChange,
  placeholder,
}: FilterMainComponentProps) => (
  <Select
    value={values}
    // @ts-ignore
    options={options}
    // @ts-ignore
    onChange={(e: { value: string; label: string }) =>
      onChange(e?.value || null)
    }
    isClearable={!!placeholder && values !== ""}
    placeholder={placeholder}
  />
);

export default GenericSelect;
